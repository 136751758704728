import request from '../utils/request';

//模糊查询
export const regexfetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/find/regex',
        method: 'get',
        params: query
    });
};


//查询用户内容
export const fetchData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/find',
        method: 'get',
        params: query
    });
};


export const EditData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/edit',
        method: 'post',
        data: query
    });
};


export const AddData = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/add',
        method: 'post',
        data: query
    });
};

//批量添加用户模板   -导出模板
export const AddAllExports = query => {
    return request({
        url: process.env.VUE_APP_URL+'user/All/exports',
        method: 'post',
        data: query
    });
};

//批量添加用户模板   -导入模板 上传地址
export const Actions = () => {
    return process.env.VUE_APP_URL+'user/All/exports/init'
};


//批量添加用户模板   -添加
export const AddAlladd= query => {
    return request({
        url: process.env.VUE_APP_URL+'user/All/exports/add',
        method: 'post',
        data: query
    });
};