import request from '../../utils/request';

/**
 * 组件-选择委员会
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'components/Committee/find',
        method: 'get',
        params: query
    });
};





