<template>
  <div>
    <el-dialog v-model="edit" width="1000px">
      <el-table
          :data="data"
          height="500px"
          border
          class="tableClass"
          ref="Table"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Name" label="姓名" fixed></el-table-column>
        <el-table-column prop="Mobile" label="手机号码" fixed></el-table-column>
                <el-table-column :label="item" v-for="(item, index) in header" :key="index">
                  <template #default="scope">
                    {{ scope.row.TolType[index].Number }} QB
                  </template>
                </el-table-column>
        <el-table-column label="总贡献值" fixed="right">
          <template #default="scope">
            {{ scope.row.ToNumber }} QB
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="close">关闭</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      edit: false,
      header: []
    }
  },
  methods: {
    close(){
      this.edit = false
      this.header=[]
      this.data=[]
    },
    open(data) {
      this.edit = true
      this.header=[]
      this.data=[]
      let TypeData = data[0].TolType

      for (let item in TypeData) {
        this.header.push(TypeData[item].Type)
      }
      setTimeout(()=>{
        this.data = data
        // this.$refs.Table.doLayout()
      },100)

    }
  }
}
</script>

<style scoped lang="scss">
.tableClass{  //table的class
  ::v-deep .el-table__fixed, ::v-deep .el-table__fixed-right{
    height: 100% !important; //设置高优先，以覆盖内联样式
  }
}


</style>
