import request from '../../utils/request';

/**
 * 贡献值确认管理
 * @param query
 * @returns {AxiosPromise}
 */

//查询数据
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/find',
        method: 'get',
        params: query
    });
};

//不通过申请
export const setfail = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/fail',
        method: 'post',
        data: query
    });
};

//生成签署文档
export const Generate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/generate',
        method: 'post',
        data: query
    });
};

//重置数据
export const ResetData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/resetData',
        method: 'post',
        data: query
    });
};






//发起签署
export const Initiate = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/initiate',
        method: 'post',
        data: query
    });
};

//终止签署流程
export const revokeSign = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/revoke',
        method: 'post',
        data: query
    });
};

export const setflows = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/flows',
        method: 'post',
        data: query
    });
};



/**
 * 根据合同ID查询签署状态
 * @param query
 * @returns {AxiosPromise}
 */

export const SignersUser = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Integral/Cpproval/SignersUser',
        method: 'post',
        data: query
    });
};




