<template>
  <div>
    <el-dialog v-model="view.user" width="900px" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form ref="form" :model="query" label-width="60px">
        <el-form-item label="关键词">
          <el-input v-model="query.Username" placeholder="请输入搜索关键词" class="handle-input mr10"   size="small"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button   size="small"  icon="el-icon-search" @click="handleSearch">搜索</el-button>
          <el-button type="text" style="margin-left: 30px" @click="ResetSearch">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table
          height="300"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          size="small"
      >
        <el-table-column prop="Name" label="姓名" width="180"></el-table-column>
        <el-table-column prop="Mobile" label="手机号码"></el-table-column>
        <el-table-column width="100" label="操作" align="center">
          <template #default="scope">
            <el-button
                type="text"
                @click="handlSelected( scope.row)"
            >选中
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view.user = false"   size="small">取 消</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {regexfetchData, fetchData} from "../api/User";

export default {
  data() {
    return {
      query: {
        Username: '',
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
      view: {
        user: false
      },
    };
  },
  methods:{
    handlAdd() {
      this.view.user = true
      this.getData()
    },
    ResetSearch() {
      this.query.pageIndex = 1
      this.query.name = ''
      this.getData()
    },
    handleSearch() {
      let name = this.query.Username
      this.tableData = []
      this.loading = true
      this.query.pageTotal = 0
      this.query.pageIndex = 1
      regexfetchData({name}).then(res => {
        this.loading = false
        let data = res.sum
        this.tableData = data
      })
    },
    getData() {
      this.loading = true
      fetchData({
        skip: this.query.pageIndex
      }).then(res => {
        let bindata = res.sum;
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
        this.loading = false
      });
    },
    handlSelected(row) {
      this.$parent.handlSelected(row);
      this.view.user = false
    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    },

  }
}
</script>

<style scoped>

</style>