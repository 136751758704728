<template>
  <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false"
             :show-close="false" width="1000px">
    <el-table :data="Data" height="300">
      <el-table-column prop="Name" label="机构名称"></el-table-column>
      <el-table-column prop="IdNumber" label="社会代码"></el-table-column>
      <el-table-column prop="UserName" label="负责人"></el-table-column>
      <el-table-column prop="UserMobile" label="手机号码"></el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button
              type="text"
              @click="handleSign( scope.row)"
          >选中
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
    <span class="dialog-footer">
           <el-button @click="view = false" size="small">取 消</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>
import {MechfetchData} from "../api/Sign";

export default {
  data() {
    return {
      view: false,
      Data: []
    }
  },
  methods: {
    getdata() {
      MechfetchData().then(res => {
        this.Data = res.sum
      })
    },
    open() {
      this.view = true
      this.getdata()
    },
    handleSign(data) {
      this.$parent.handleSign(data)
      this.view = false
    }
  }
}
</script>

<style scoped>

</style>