import request from '../../utils/request';

/**
 * 查询经办合同  带上用户ID
 * @param query
 * @returns {AxiosPromise}
 */
export const fetchData = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/find',
        method: 'get',
        params: query
    });
};

/**
 * 生成合同并签署发起
 * @param query
 * @returns {AxiosPromise}
 */
export const fileAddSign = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/launchSign/fileAdd',
        method: 'post',
        data: query
    });
};


/**
 * 撤销签署合同
 * @param query
 * @returns {AxiosPromise}
 */

export const revokeSign = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/launchSign/revoke',
        method: 'post',
        data: query
    });
};

/**
 * 归档确认合同
 * @param query
 * @returns {AxiosPromise}
 */

export const confirmFlowId = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/launchSign/confirm',
        method: 'post',
        data: query
    });
};

/**
 * 查看上传文档
 * @param query
 * @returns {AxiosPromise}
 */


export const openFileId = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/fileId',
        method: 'get',
        params: query
    });
};


/**
 * 查看签署文档
 * @param query
 * @returns {AxiosPromise}
 */

export const openFlowId = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/flowId',
        method: 'get',
        params: query
    });
};

/**
 * 模糊查询姓名（非手机壳）
 * @param query
 * @returns {AxiosPromise}
 */

export const NameSearch = query => {
    return request({
        url:process.env.VUE_APP_URL+ 'Contract/NameSearch',
        method: 'get',
        params: query
    });
};








