<template>
  <div>
    <el-dialog v-model="view" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false" width="1000px">
      <el-table :data="Data" height="300">
        <el-table-column prop="ComName" label="社区项目昵称"></el-table-column>
        <el-table-column prop="Title" label="委员会昵称"></el-table-column>
        <el-table-column prop="NewTime" label="添加时间"></el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-button
                type="text"
                @click="handleAdd( scope.row)"
            >选中
            </el-button>
            <el-button
                type="text"
                @click="openMember( scope.row.member)"
            >查看人员
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
    <span class="dialog-footer">
           <el-button @click="view = false" size="small">取 消</el-button>
    </span>
      </template>
    </el-dialog>
    <el-dialog v-model="user" width="1000px">
      <el-table :data="userData" height="300">
        <el-table-column prop="UserName" label="用户姓名"></el-table-column>
        <el-table-column prop="UserMobile" label="手机号码"></el-table-column>
        <el-table-column prop="NewTime" label="添加时间"></el-table-column>
      </el-table>
      <template #footer>
    <span class="dialog-footer">
           <el-button @click="user = false" size="small">关 闭</el-button>
    </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {fetchData} from "../api/components/Committee";

export default {
  data() {
    return {
      view: false,
      user: false,
      Data: [],
      userData: [],
      query: {
        App_id: ""
      }
    }
  },
  methods: {
    openMember(Member) {
      if (Member.length >= 1) {
        this.userData = Member
        this.user = true
      } else {
        this.$message.error('成员为空，无法查看');
      }
    },
    getdata() {
      let App_id = this.query.App_id
      fetchData({App_id}).then(res => {
        this.Data = res.sum
      })
    },
    open(App_id) {
      this.view = true
      this.query.App_id = App_id
      this.getdata(App_id)
    },
    handleAdd(data) {
      if (data.member.length >= 1) {
        this.$parent.CommitteeAdd(data)
        this.userData = []
        this.Data = []
        this.view = false
      } else {
        this.$message.error('成员为空，无法添加');
      }
    }
  }
}
</script>

<style scoped>

</style>