<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 贡献值确认管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container" v-loading="loading">
      <el-table
          :data="tableData"
          border
          class="table"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="Title" label="社区项目" width="190"></el-table-column>
        <el-table-column prop="TitleName" label="活动昵称" width="250" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="分配贡献值详情">
          <template #default="scope">
            <el-button type="text" @click="openDetails(scope.row.initData)">详情</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="ToNumber" label="总贡献值">
          <template #default="scope">
            {{scope.row.ToNumber}}QB
          </template>
        </el-table-column>
        <el-table-column prop="NewTime" label="创建日期"></el-table-column>
        <el-table-column label="状态">
          <template #default="scope">
            <el-tag type="success" v-if="scope.row.StepsStart=='签署已完成'">{{scope.row.StepsStart}} / 已发放贡献值</el-tag>
            <el-tag type="warning" v-else>{{scope.row.StepsStart}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="180" label="操作">
          <template #default="scope">
            <el-button
                type="text"
                @click="launch( scope.row)"
            >管理
            </el-button>
            <el-button
                type="text"
                class="red"
                v-if="scope.row.StepsStart=='文档已生成'||scope.row.StepsStart=='未生成文档'||scope.row.StepsStart=='签署已终止'"
                @click="fail( scope.row.App_id,scope.row.id)"
            >不通过
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="query.pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <DetailsType ref="DetailsType"></DetailsType>
    <DetailsGenerate ref="DetailsGenerate"></DetailsGenerate>
  </div>
</template>

<script>
import DetailsType from '../Integral/com/DetailsType'
import DetailsGenerate from './com/DetailsGenerate'
import {openFileId, openFlowId} from "../../api/Contract";
import {fetchData, setfail} from "../../api/Integral/Cpproval";


export default {
  data() {
    return {
      loading:false,
      query: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    openDetails(TypeData) {
      let data = JSON.parse(TypeData)
      this.$refs.DetailsType.open(data)
    },
    openflowId(FlowId) {
      this.openLoading()
      openFlowId({FlowId}).then(res => {
        this.closeLoading()
        if (res.code == 0) {
          window.open(res.fileUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    launch(data) {
      this.$refs.DetailsGenerate.open(data)
    },
    fail(App_id, id) {
      let UserInfo = this.$store.state.UserInfo
      let Admin_id = UserInfo.Admin_id

      this.$confirm("确定要执行不通过吗？", "提示", {
        type: "warning"
      }).then(() => {
        this.openLoading()
        setfail({Admin_id, App_id, id}).then(res => {
          this.closeLoading()
          if (res.code == 0) {
            this.$message.success(res.message);
            this.getData()
          } else {
            this.$message.error(res.message);
          }
        });

      }).catch(() => {
      });
    },
    openfile(FileId) {
      openFileId({FileId}).then(res => {
        if (res.code == 0) {
          window.open(res.downloadUrl)
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getData() {
    this.loading=true
      fetchData({
        skip: this.query.pageIndex
      }).then(res => {
       this.loading=false
        let bindata = res.sum;
        let number = res.number
        this.query.pageTotal = number
        this.tableData = bindata;
      });
    },
    openLoading(data) {
      if (!data) {
        data = '处理中'
      }
      this.loading = this.$loading({
        lock: true,
        text: data,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    closeLoading() {
      this.loading.close();
    },
  },
  components: {
    DetailsType,
    DetailsGenerate
  }
}


</script>

<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
}

.table {
  width: 100%;
  font-size: 14px;
}

.green {
  color: #00a854;
}

.red {
  color: red;
}
</style>
